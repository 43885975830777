<template>
  <div
    class="recycling-event-item d-flex flex-row align-items-center position-relative px-3 py-2"
  >
    <div class="mr-3" style="max-width: 30px">
      <img
        :src="item.category_icon_url"
        class="img-fluid rounded"
        style="max-width: 30px; border-radius: 15px !important"
      />
    </div>
    <div>
      <div class="list-item-head">
        {{ item.date | moment("dd. DD.MM.YYYY") }}
      </div>
      <div v-if="showStreet" class="list-item-title">
        {{ item.street.name }}
      </div>
      <div class="list-item-detail">{{ item.category }}</div>
    </div>
    <a href="#" @click.prevent="newsItemClicked()" class="stretched-link"></a>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    showStreet: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
